import { createStyles } from '@mantine/core';

// Configs
import colors from '../../../../mantine/configs/colors.configs';

// Helpers
import UtilsHelper from '../../../helpers/utils.helper';

const useStyles = createStyles((theme) => ({
  title: {
    color: colors.black.bean,
    fontFamily: 'primary',
    fontWeight: 700,
    fontSize: UtilsHelper.toRem(19),

    [theme.fn.largerThan('tablet')]: {
      fontSize: UtilsHelper.toRem(30),
    },
  },

  subtitle: {
    color: colors.black.bean,
    fontFamily: 'secondary',
    fontWeight: 700,
    fontSize: UtilsHelper.toRem(11),

    [theme.fn.largerThan('tablet')]: {
      fontSize: UtilsHelper.toRem(16),
    },
  },

  text: {
    color: colors.black.bean,
    fontFamily: 'secondary',
    fontWeight: 400,
    textAlign: 'justify',
    fontSize: UtilsHelper.toRem(11),

    [theme.fn.largerThan('tablet')]: {
      fontSize: UtilsHelper.toRem(16),
    },
  },
}));

export default useStyles;
