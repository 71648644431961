// Paths
import routesPaths from './routesPaths.configs';

import LazyLoadComponent from '../../shared/layout/LazyLoadComponent';

/**
 * appRoutes configuration for the application.
 *
 * Each route consists of a route, path, and component to render.
 *
 * - `route`: The route pattern, which may contain dynamic segments.
 * - `path`: The actual path for the route, without any dynamic segments.
 * - `element`: The React component to render for the route.
 */
const appRoutes = {
  notFound: {
    route: routesPaths.notFound,
    path: routesPaths.notFound,
    element: LazyLoadComponent('NotFound'),
  },

  home: {
    route: `${routesPaths.home}`,
    path: routesPaths.home,
    element: LazyLoadComponent('Home'),
  },

  category: {
    route: `${routesPaths.category}`,
    path: routesPaths.category,
    element: LazyLoadComponent('Entity'),
  },

  product: {
    route: `${routesPaths.product}`,
    path: routesPaths.product,
    element: LazyLoadComponent('Entity'),
  },

  certification: {
    route: `${routesPaths.certification}`,
    path: routesPaths.certification,
    element: LazyLoadComponent('Certification'),
  },

  issueRequest: {
    route: `${routesPaths.issueRequest}`,
    path: routesPaths.issueRequest,
    element: LazyLoadComponent('Certification'),
  },

  success: {
    route: `${routesPaths.success}`,
    path: routesPaths.success,
    element: LazyLoadComponent('Success'),
  },

  // faq: {
  //   route: `${routesPaths.faq}`,
  //   path: routesPaths.faq,
  //   element: LazyLoadComponent('Faq'),
  // },
};

export default appRoutes;
