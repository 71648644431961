import colors from './colors.configs';

const globalStyles = {
  html: {
    boxSizing: 'border-box',
    scrollBehavior: 'smooth',
  },
  '*, *::before, *::after': {
    boxSizing: 'inherit',
    margin: 0,
    padding: 0,
  },
  'html, body, #root': {
    width: '100%',
    height: 'fit-content',
  },

  '#root': {
    backgroundColor: colors.white.whiteSmoke,
  },

  a: {
    color: 'inherit',
    textDecoration: 'none',
  },

  button: {
    backgroundColor: 'transparent',
    borderWidth: '0px',
  },

  /* Resolve blue zone mobile */
  'a, button': {
    WebkitTapHighlightColor: 'transparent !important',
  },

  /* *************************** SCROLL BAR ************************** */

  '::-webkit-scrollbar': {
    width: '2px',
    height: '8px',
    backgroundColor: colors.gray.silver,
  },

  /* Handle */
  '::-webkit-scrollbar-thumb': {
    border: '4px solid rgba(0, 0, 0, 0)',
    WebkitBorderRadius: '10px',
    borderRadius: '10px',
    background: colors.brown.saddleBrown,
  },

  '::-webkit-scrollbar-thumb:window-inactive': {
    background: colors.brown.saddleBrown,
  },

  /* *************************** POSITIONING CLASSES ************************** */
  '.centred': {
    textAlign: 'center',
  },

  '.fully': {
    width: '100%',
    height: '100%',
  },

  '.righty': {
    textAlign: 'right',
  },

  '.lefty': {
    textAlign: 'left',
  },
};

export default globalStyles;
