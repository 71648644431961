/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
// Lib dependencies
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

// Others
import useStore from '../shared/store';

import routesPaths from '../router/configs/routesPaths.configs';

/* -------------------------------------------------------------------------- */
/*                                    Hook                                    */
/* -------------------------------------------------------------------------- */

const useRouterNavigation = () => {
  const { entity } = useStore();

  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Navigates to the specified path.
   *
   * @param {string} targetPath - The path to navigate to.
   * @param {object} searchParams - Optional search parameters.
   */
  const navigateTo = (targetPath, searchParams = {}) => {
    let fullPath = targetPath;

    if (entity.isLoaded) {
      searchParams.UID = entity.data.reference;
    }

    if (searchParams) {
      fullPath = `${fullPath}?${queryString.stringify(searchParams)}`;
    }

    navigate(fullPath);
  };

  /**
   * Navigates back to the previous page.
   */
  const navigateBack = () => {
    navigate(-1);
  };

  /**
   * Opens the specified URL in a new tab.
   *
   * @param {string} targetPath - The URL to open in a new tab.
   */
  const openNewTab = (targetPath) => {
    window.open(targetPath, '_blank');
  };

  const getCurrentPath = () => {
    function camelCaseToKebabCase(input) {
      return input.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    }
    const keysInKebabCase = Object.keys(routesPaths).map(camelCaseToKebabCase);

    const matchingKey = keysInKebabCase.find((key) => location.pathname.includes(key));
    return matchingKey || null;
  };

  return { navigateTo, navigateBack, openNewTab, getCurrentPath };
};

export default useRouterNavigation;
