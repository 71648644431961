const routesPaths = {
  notFound: '/404',
  home: '/',
  category: '/category',
  product: '/product',
  certification: '/certification',
  issueRequest: '/issue-request',
  success: '/certification/success',
  // faq: '/faq',
};

export default routesPaths;
